import React from "react";
import SEO from "../components/common/seo.js";
import Layout from "../components/common/layout";
import Navigation from "../components/navigation/navigation";
import Header from "../components/sections/header";
import Map from "../components/sections/map";
import Contact from "../components/sections/contact";
import { Dot, Container } from "../components/global";
import styled from "styled-components";
import { ADDRESS } from "../components/const";
const CareerPolityka = () => (
  <Layout>
    <SEO
      title='Kariera'
      description='MWDK / Waś, Krajewski, & Wspólnicy z siedzibą w Warszawie przy ulicy
          Nowy Świat 6/12 podejmie współpracę ze studentami, magistrami prawa,
          aplikantami lub radcami prawnymi / adwokatami.'
    />
    <Navigation />
    <Header />
    <Article id='page'>
      <Title>KARIERA</Title>

      <Container>
        <p>
          MWDK / Waś, Krajewski & Wspólnicy z siedzibą w Warszawie przy ulicy
          Nowy Świat 6/12 podejmie współpracę ze studentami, magistrami prawa,
          aplikantami lub radcami prawnymi / adwokatami.
        </p>
        <List>
          <Dot>
            Zgłoszenia prosimy przesyłać na adres e-mail:{" "}
            <a href={`mailto:${ADDRESS.mail}`}>{ADDRESS.mail}</a>
          </Dot>
          <Dot>W tytule maila prosimy wpisać „REKRUTACJA”</Dot>
          <Dot>
            W treści CV / Resume oraz e-maila prosimy o zamieszczenie
            następującej zgody na przetwarzanie danych osobowych:
          </Dot>
          <Rodo>
            „Wyrażam zgodę na przetwarzanie moich danych osobowych przez
            Administratora – MWDK / Waś, Krajewski & Wspólnicy z siedzibą w
            Warszawie (00-400) przy ul. Nowy Świat 6/12 - zawartych w złożonej
            dokumentacji w celu przeprowadzenia postępowania rekrutacyjnego”.
          </Rodo>
        </List>
        <p>
          Uprzejmie informujemy, że skontaktujemy się jedynie w wybranymi
          osobami.
        </p>
        <h5>Przyszłe rekrutacje</h5>
        <p>
          Jeśli wyraża Pan/Pani zgodę na wykorzystywanie swoich danych osobowych
          na potrzeby przyszłych rekrutacji / procesów współpracy, prosimy o
          zamieszczenie w CV / Resume następującej klauzuli:
        </p>
        <Rodo>
          „Wyrażam zgodę na przetwarzanie moich danych osobowych przez
          Administratora – MWDK / Waś, Krajewski & Wspólnicy z siedzibą w
          Warszawie (00-400) przy ul. Nowy Świat 6/12 - dla celów przyszłych
          rekrutacji / procesów współpracy”.
        </Rodo>
        <h5>Klauzula informacyjna</h5>
        <h6>I. Kto jest Administratorem Pani/Pana danych osobowych?</h6>
        <p>
          Administratorem Pani/Pana danych osobowych jest MWDK/ Waś, Krajewski &
          Wspólnicy z siedzibą w Warszawie przy ul. Nowy Świat 6/12, 00-400
          Warszawa (dalej jako: „Administrator”). Administrator nie wyznaczył
          Inspektora Ochrony Danych. Wszelkie kwestie dotyczące ochrony danych
          osobowych można kierować na adres e-mail:{" "}
          <a href={`mailto:${ADDRESS.mail}`}>{ADDRESS.mail}</a>.
        </p>
        <h6>
          II. W jakim celu Administrator przetwarza Pani/Pana dane osobowe?
        </h6>
        <p>
          Administrator przetwarza Pani/Pana dane osobowe dla następujących
          celów:
        </p>
        <List>
          <Dot>
            rekrutacji oraz współpracy, a także dla celów przyszłych rekrutacji
            / procesów współpracy prowadzonych przez Administratora
          </Dot>
          <Dot>
            wykonywania przez Administratora obowiązków wynikających z prawa
            pracy oraz prawa cywilnego związanych z procesem zatrudnienia i
            współpracy.
          </Dot>
        </List>
        <h6>
          III. Jaka jest podstawa prawna przetwarzania Pani/Pana danych
          osobowych?
        </h6>
        <p>
          W zależności od celu przetwarzania Pani/Pana danych osobowych,
          przetwarzanie może opierać się na wskazanych poniżej podstawach
          prawnych:
        </p>
        <List>
          <Dot>
            rekrutacja / współpraca – podstawą jest Pani/Pana zgoda wyrażona
            poprzez zamieszczenie w CV/Resume oraz liście motywacyjnym klauzuli
            zgody na przetwarzanie danych osobowych;
          </Dot>
          <Dot>
            wykonywanie obowiązków wynikających z prawa pracy i prawa cywilnego
            – podstawą są obowiązki prawne ciążące na Administratorze,
            wynikające z prawa krajowego, np. Kodeksu pracy oraz Kodeksu
            cywilnego.
          </Dot>
        </List>

        <h6>IV. Jak długo Pani/Pana dane osobowe będą przetwarzane?</h6>
        <p>
          W przypadku, gdy Pani/Pana dane osobowe są przetwarzane na podstawie
          zgody dane będą usuwane po zakończeniu procesu rekrutacji na dane
          stanowisko. W sytuacji wyrażenia zgody na przetwarzanie danych
          osobowych dla celów przyszłych rekrutacji / współpracy, Pani/Pana dane
          osobowe zostaną usunięte nie później niż po upływie trzech lat – o ile
          wcześniej Pani/Pan nie cofnie udzielonej zgody. Zgodę można cofnąć
          pisząc na adres e-mail:{" "}
          <a href={`mailto:${ADDRESS.mail}`}>{ADDRESS.mail}</a>. W przypadku,
          gdy Administrator przetwarza Pani/Pana dane osobowe ze względu na
          ciążący na nim obowiązek prawny, dane zostaną usunięte po upływie
          okresu określonego przez przepisy prawa.
        </p>

        <h6>
          V. Jakim podmiotom Administrator przekazuje Pani/Pana dane osobowe?
        </h6>
        <p>
          Administrator nie będzie przekazywać Pani/Pana danych osobowych innym
          podmiotom.
        </p>

        <h6>
          VI. Czy Pani/Pana dane osobowe będą przekazywane poza obszar EOG?
        </h6>
        <p>
          Administrator nie zamierza przekazywać Pana/Pani danych osobowych do
          państw trzecich, tj. państw zlokalizowanych poza terytorium
          Europejskiego Obszaru Gospodarczego oraz organizacjom międzynarodowym.
        </p>

        <h6>
          VII. Jakie prawa przysługują Pani/Panu w związku z ochroną danych
          osobowych?
        </h6>
        <p>
          Przysługuje Pani/Panu prawo dostępu do swoich danych osobowych,
          żądanie ich sprostowania, usunięcia, ograniczenia przetwarzania,
          przeniesienia oraz prawo sprzeciwu wobec przetwarzania danych.
        </p>
        <p>
          W sytuacji, gdy Pani/Pana dane osobowe są przetwarzane w oparciu o
          wyrażoną zgodę, można ją cofnąć w każdym czasie, bez względu na
          zgodność z prawem przetwarzania dokonanego przed jej wycofaniem.
        </p>
        <p>
          W związku z przetwarzaniem Pani/Pana danych osobowych przez
          Administratora, przysługuje Pani/Panu prawo wniesienia skargi do
          krajowego organu nadzoru (Prezesa Urzędu Ochrony Danych Osobowych) w
          sytuacji, gdy Administrator naruszy przepisy Rozporządzenia Parlamentu
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
          danych).
        </p>

        <h6>
          VIII. Czy Pani/Pana dane osobowe mogą zostać wykorzystane do
          zautomatyzowanego podejmowania decyzji w indywidualnych przypadkach,
          włącznie z profilowaniem?
        </h6>
        <p>
          Administrator nie będzie wykorzystywał Pani/Pana danych osobowych w
          celu podejmowania zautomatyzowanych decyzji, w tym profilowania.
        </p>
      </Container>
    </Article>
  </Layout>
);

export default CareerPolityka;

const Rodo = styled.p`
  font-style: italic;
  text-align: left;
  font-size: ${(props) => props.theme.font.size.xxsmall};
`;
const Title = styled.h4`
  margin-top: 75px;
`;

const List = styled.ul`
  /* padding: 40px 0px 150px; */
  li {
    /* padding: 0; */
    list-style-position: inside;
    text-indent: -1.5em;
    padding-left: 1.5em;
    line-height: 1.4;
  }
`;
const Article = styled.article`
  padding: 30px 0 100px;
  strong {
    font-weight: ${(props) => props.theme.font.weight.regular};
  }
  h6 {
    font-size: ${(props) => props.theme.font.size.xsmall};
    font-weight: ${(props) => props.theme.font.weight.light};
    padding: 0;
    margin: 0;
  }
`;
